import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Overview = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "overview" } }) {
        edges {
          node {
            childImageSharp {
              fluid(cropFocus: CENTER, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout
      prev={{ name: "큐레이터 토크", link: "/talk" }}
      next={{ name: "크레딧", link: "/credits" }}
      title={"전시 전경"}
    >
      <SEO title="전시 전경" />
      <p className="overview-text md">
        온라인으로 진행된 전시 네오-트라이브2020: 사회가 잠시 멀어졌을 때는
        2020년 5월 11일부터 동월 24일까지 진행되었다. 끝나면 사진이나 영상과
        같은 기록으로 남는 다른 전시들과 마찬가지로, 네오-트라이브2020 역시
        전시의 막을 내린 후 웹사이트에 그 전시의 전경을 화면 녹화 영상과 캡쳐
        이미지로 기록한다.
      </p>
      <br />
      {data.allFile.edges.map(({ node }, ind) => (
        <div key={ind} className="img-container">
          <Img fluid={node.childImageSharp.fluid} />
        </div>
      ))}
      <br />

      <div className="iframe-container">
        <iframe
          src="https://www.youtube.com/embed/fvGJcV_SOYk"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Layout>
  )
}

export default Overview
